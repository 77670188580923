<template>
    <div id="people-trend">
        <div class="peopel-time-search">
            <div class="peopelTimeSearch-left" @click="searchSevenOrFifteen">
                <div class="peopel-time-lastseven" :id="activeTime === 7 ? 'activeTime' : ''">最近7天</div>
                <div class="peopel-time-lastfifteen" :id="activeTime === 15 ? 'activeTime' : ''">最近15天</div>
            </div>
            <div class="peopelTimeSearch-right">
                <div class="peopelTimeSearch-time">
                    <el-date-picker
                            v-model="chooseDay"
                            type="daterange"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :default-time="['00:00:00', '23:59:59']"
                    ></el-date-picker>
                </div>
                <button @click="checkDate()">查询</button>
            </div>
        </div>
        <div class="drawTrendEcharts">
            <div id="drawTrend"></div>
        </div>
    </div>
</template>
<script>
import {getPeopleData} from "../../api/api.js";
import {trend} from "../charts/trend.js";
import router from "@/router";

export default {
    data() {
        return {
            activeTime: 7,
            chooseDay: "",
            startTime: "",
            endTime: "",
            peopleData: [],
        };
    },
    created() {
    },
    mounted() {
        document.getElementById("export").style.display = 'show';
        //document.getElementById("export").style.display='none';
        const params = new URLSearchParams();
        params.append("token", localStorage.getItem("singleToken"));
        params.append("rangeDays", 7);
        params.append("beginDate", "");
        params.append("endDate", "");

        this.getPeopleDataFun(params);
        this.hideExport();
    },
    methods: {
        hideExport() {
            document.getElementById("export").style.display = "none";
        },
        drawTrend() {
            trend(document.getElementById("drawTrend"), this.peopleData);
        },
        searchSevenOrFifteen(e) {
            const params = new URLSearchParams();
            if (e.target.className === "peopel-time-lastseven") {
                this.activeTime = 7;
                params.append("token", localStorage.getItem("singleToken"));
                params.append("rangeDays", 7);
            } else {
                this.activeTime = 15;
                params.append("token", localStorage.getItem("singleToken"));
                params.append("rangeDays", 15);
            }
            this.getPeopleDataFun(params);
        },
        checkDate() {
            let start = `${this.chooseDay[0].getFullYear()}-${this.chooseDay[0].getMonth() + 1}-${this.chooseDay[0].getDate()}`;
            let end = `${this.chooseDay[1].getFullYear()}-${this.chooseDay[1].getMonth() + 1}-${this.chooseDay[1].getDate()}`;
            this.activeTime = -1;
            this.startTime = start;
            this.endTime = end;

            const params = new URLSearchParams();
            params.append("token", localStorage.getItem("singleToken"));
            params.append("rangeDays", -1);
            params.append("beginDate", this.startTime);
            params.append("endDate", this.endTime);

            this.getPeopleDataFun(params);
        },
        getPeopleDataFun(params) {
            getPeopleData(params).then((res) => {
                if (res.code === "API200") {
                    this.peopleDataDeal(res.data.list);
                } else if (res.code === "API300") {
                    router.push("/");
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        peopleDataDeal(list) {
            this.peopleData = [];
            for (let i in list) {
                this.peopleData.push([list[i].monthDay, list[i].nums]);
            }
            this.drawTrend();
        },
    },
};
</script>
<style lang="less">
#people-trend {
    width: 100%;
    height: 6rem;

}

.drawTrendEcharts {
    width: 10rem;
    height: 5.8rem;
    margin: 0.2rem auto 0;

    #drawTrend {
        margin: auto;
        width: 10rem;
        height: 5.8rem;
        background-color: rgba(96, 118, 173, 0.06);
        padding-top: 0.2rem;

        div {
            width: 10rem;
            height: 7rem;
        }

        canvas {
            width: 10rem;
            height: 7rem;
        }
    }
}

.peopel-time-search {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;

    .peopelTimeSearch-left {
        display: flex;

        div {
            width: 1.5rem;
            height: 0.4rem;
            cursor: pointer;
            line-height: 0.4rem;
            text-align: center;
            background: rgba(16, 28, 55, 1);
            color: rgba(162, 164, 206, 1);
            border: 1px solid rgba(96, 118, 173, 1);
            border-radius: 2px;
        }

        :nth-child(2) {
            margin-left: 10px;
        }

        #active-time {
            background: rgba(11, 22, 48, 1);
            color: rgba(0, 216, 255, 1);
            border: 1px solid rgba(96, 118, 173, 1);
            border-radius: 2px;
        }
    }

    .peopelTimeSearch-right {
        display: flex;
        justify-content: space-around;
        width: 4.5rem;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        .peopelTimeSearch-time {
            width: 3.3rem;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                width: 30px;
                color: rgba(162, 164, 206, 1);
            }

            .el-input__inner {
                width: 2.82rem;
                height: 0.42rem;
                line-height: 0.42rem;
                text-align: center;
                background: rgba(16, 28, 55, 1);
                color: rgba(162, 164, 206, 1);
                border: 1px solid rgba(96, 118, 173, 1);
                border-radius: 2px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .el-icon-date:before {
                    content: "";
                }

                .el-input__icon:after {
                    content: "";
                }

                .el-date-editor {
                    height: 0;
                }

                .el-range-separator {
                    height: 100%;
                    // display: none;
                    // line-height: 0.42rem;
                }

                input {
                    background: none;
                    font-size: 18px;
                    color: rgba(162, 164, 206, 1);
                }
            }
        }

        button {
            width: 1.06rem;
            height: 0.4rem;
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 800;
            color: rgba(255, 255, 255, 1);
            line-height: 0.36rem;
            background: linear-gradient(90deg, #253379, #4D7B8F);
            border: 1px solid #27417B;
            border-radius: 2px;
            background: url(../../assets/homeImg/daochu_back.png) no-repeat;
            background-size: 100% 100%;
        }
    }
}
</style>

