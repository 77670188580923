<template>
    <div id="inoutDetail console-wrapper" class="inout-content">

        <ul class="inoutDetail-list1">
            <li class="table-th">
                <span>位置</span>
                <span>时间</span>
                <span>性别</span>
                <span>年龄</span>
            </li>
        </ul>
        <ul class="inoutDetail-list">
            <!--            <li class="table-th">-->
            <!--                <span>位置</span>-->
            <!--                <span>时间</span>-->
            <!--                <span>性别</span>-->
            <!--                <span>年龄</span>-->
            <!--            </li>-->
            <li v-for="(item, index) in inOutList" :key="index" class="table-tr">
                <p>{{ item.deviceName }}</p>
                <p>
                    {{ item.day }}
                    <span>{{ item.time }}</span>
                </p>
                <p>{{ item.sex }}</p>
                <p>{{ item.age }}</p>
            </li>
        </ul>
        <div class="inoutDetail-pagination">
            <el-pagination
                    layout="prev, next, sizes, jumper, total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :background="true"
                    :prev-text="'上一页'"
                    :next-text="'下一页'"
                    :page-sizes="[15, 50, 100]"
                    :pager-count="5"
                    :total=totalCount
            ></el-pagination>
        </div>
    </div>
</template>
<script>
import {getInOutData} from "../../api/api.js";
import router from "@/router";

export default {
    data() {
        return {
            pageNo: 1,
            pageSize: 15,
            inOutList: [],
            totalCount: 0,
        };
    },
    mounted() {
        document.getElementById("export").style.display = 'show';
        //document.getElementById("export").style.display='none';
        const params = new URLSearchParams();
        params.append("token", localStorage.getItem("singleToken"));
        params.append("pageNo", 1);
        params.append("pageSize", 15);

        this.getInoutDataFun(params);
        this.hideExport();
    },
    methods: {
        hideExport() {
            document.getElementById("export").style.display = "block";
        },
        handleSizeChange(val) {
            this.pageSize = val;
            const params = new URLSearchParams();
            params.append("token", localStorage.getItem("singleToken"));
            params.append("pageNo", this.pageNo);
            params.append("pageSize", this.pageSize);

            this.getInoutDataFun(params);
        },
        handleCurrentChange(val) {
            this.pageNo = val;
            const params = new URLSearchParams();
            params.append("token", localStorage.getItem("singleToken"));
            params.append("pageNo", this.pageNo);
            params.append("pageSize", this.pageSize);
            this.getInoutDataFun(params);
        },
        getInoutDataFun(params) {
            getInOutData(params).then((res) => {
                if (res.code === "API200") {
                    this.InoutDataDeat(res.data.list);
                    this.totalCount = res.data.total;
                } else if (res.code === "API300") {
                    router.push("/");
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        InoutDataDeat(list) {
            this.inOutList = [];
            for (let i in list) {
                let day = list[i].dataTime.split(" ")[0];
                let time = list[i].dataTime.split(" ")[1];
                list[i].day = day;
                list[i].time = time;
            }
            this.inOutList = list;
        },
    },
};
</script>
<style lang="less">
#inoutDetail {
    width: 100%;
    height: 7rem;
}

.inout-content {
    width: 100%;
    height: 7rem;
    margin-top: 20px;
}

.inoutDetail-list1 {
    width: 100%;
    overflow: auto;

    li {
        width: 100%;
        height: 0.46rem;
        line-height: 0.46rem;
        color: rgba(221, 223, 251, 1);
        font-size: 16px;
        text-align: center;
        display: flex;
        justify-content: space-around;

        :nth-child(1) {
            width: 30%;
        }

        :nth-child(2) {
            width: 40%;

            span {
                font-size: 12px;
            }
        }

        :nth-child(3) {
            width: 15%;
        }

        :nth-child(4) {
            width: 15%;
        }
    }
}

.inoutDetail-list {
    width: 100%;
    height: 80%;
    overflow: auto;

    li {
        width: 100%;
        height: 0.46rem;
        line-height: 0.46rem;
        color: rgba(221, 223, 251, 1);
        font-size: 16px;
        text-align: center;
        display: flex;
        justify-content: space-around;

        :nth-child(1) {
            width: 30%;
        }

        :nth-child(2) {
            width: 40%;

            span {
                font-size: 12px;
            }
        }

        :nth-child(3) {
            width: 15%;
        }

        :nth-child(4) {
            width: 15%;
        }
    }

    li:nth-child(even) {
        background: rgba(96, 118, 173, 0.06);
        border-radius: 2px;
    }
}

.inoutDetail-list::-webkit-scrollbar {
    width: 8px;
    background: #3F5381;
}

.inoutDetail-list::-webkit-scrollbar-thumb {
    background-color: #090D26;
    border-radius: 10px;
    -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
}

.inoutDetail-list1 .table-th {
    font-size: 18px;
    font-weight: 800;
    color: #00D8FF;
    font-family: PingFang SC;
}

.inoutDetail-pagination {
    width: 50%;
    margin: auto;
    margin-top: 30px;
    // element样式重写
    .el-pagination {
        .btn-prev,
        .btn-next {
            width: 0.7rem;
            padding: 0 3px;
            background: rgba(255, 255, 255, 0.06);
            border: 1px solid rgba(96, 118, 173, 1);
            border-radius: 4px;
            color: rgba(135, 160, 220, 1);
        }

        .el-pager {
            .number {
                width: 5px;
                height: 0.3rem;
                font-size: 15px;
                font-family: PingFang SC;
                font-weight: 400;
                color: rgba(135, 160, 220, 1);
                line-height: 0.35rem;
                background: none;
            }

            :active {
                background-color: none;
            }

            li {
                width: 13px;
                height: 3px;
                font-size: 15px;
                font-family: PingFang SC;
                font-weight: 400;
                color: rgba(135, 160, 220, 1);
                line-height: 0.35rem;
                background: none;
            }
        }

        .el-input__inner {
            //   width: 102px;
            height: 0.28rem;
            background: rgba(255, 255, 255, 0.06);
            border: 1px solid rgba(96, 118, 173, 1);
            border-radius: 4px;
        }

        .el-pagination__jump {
            //   width: 50px;
            height: 0.28rem;
            border-radius: 4px;
        }
    }
}
</style>
