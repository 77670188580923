<template>
    <div id="gender-propportion">
        <div class="gender-propportioon">
            <div class="gender-propportion-title">
                <span class="gender-propportion-title-icon"></span>
                <span class="gender-propportion-title-text">性别比例</span>
            </div>
            <div class="gender-propportion-content">
                <div id="draw-pie-left"></div>
                <span></span>
                <div id="draw-pie-right"></div>
            </div>
        </div>
    </div>
</template>
<script>
import {getGenderData} from "../../api/api.js";
import {pie} from "../charts/pie.js";
import router from "@/router";

export default {
    data() {
        return {
            curDay: [],
            allDay: [],
            curDayManNums: '',
            curDayWoManNums: '',
            curDayManNumsPercent: '',
            curDayWoManNumsPercent: '',
            allManNums: '',
            allWoManNums: '',
            allManNumsPercent: '',
            allWoManNumsPercent: '',
            colorListCurDay: ["#30C1D2", "#2B59E0"],
            colorListAllDay: ["#30C1D2", "#2B59E0"],
        };
    },
    created() {
        // this.testGetData();
    },
    mounted() {
        document.getElementById("export").style.display = 'show';
        //document.getElementById("export").style.display='none';
        const params = new URLSearchParams();
        params.append("token", localStorage.getItem("singleToken"));
        params.append("rangeDays", "");
        getGenderData(params).then((res) => {
            if (res.code === "API200") {
                this.genderDataDeal(res.data);
            } else if (res.code === "API300") {
                router.push("/");
            } else {
                this.$message.error(res.msg);
            }
        });
        this.hideExport();
    },
    methods: {
        hideExport() {
            document.getElementById("export").style.display = "none";
        },
        drawcurDay() {
            pie(
                document.getElementById("draw-pie-left"),
                this.curDay,
                "当日",
                this.colorListCurDay,
                "horizontal"
            );
        },
        drawallDay() {
            pie(
                document.getElementById("draw-pie-right"),
                this.allDay,
                "全部",
                this.colorListAllDay,
                "horizontal"
            );
        },
        genderDataDeal(list) {

            const curDayData = list.curDay;
            const allData = list.all;

            for (let i in curDayData) {
                if (curDayData[i].sex === 0) {//男
                    this.curDayManNums = curDayData[i].nums;
                } else if (curDayData[i].sex === 1) {//女
                    this.curDayWoManNums = curDayData[i].nums;
                }
            }

            if (this.curDayManNums === 0) {
                this.curDayManNumsPercent = 0;
                if (this.curDayWoManNums === 0) {
                    this.curDayWoManNumsPercent = 0;
                } else {
                    this.curDayWoManNumsPercent = 100;
                }
            } else {
                if (this.curDayWoManNums === 0) {
                    this.curDayManNumsPercent = 100;
                    this.curDayWoManNumsPercent = 0;
                }
            }
            if (this.curDayManNums !== 0 && this.curDayWoManNums !== 0) {
                this.curDayManNumsPercent = Math.round(this.curDayManNums / (this.curDayManNums + this.curDayWoManNums) * 100);
                this.curDayWoManNumsPercent = 100 - this.curDayManNumsPercent;
            }

            for (let x in allData) {
                if (allData[x].sex === 0) {//男
                    this.allManNums = allData[x].nums;
                } else if (allData[x].sex === 1) {//女
                    this.allWoManNums = allData[x].nums;
                }
            }

            if (this.allManNums === 0) {
                this.allManNumsPercent = 0;
                if (this.allWoManNums === 0) {
                    this.allWoManNumsPercent = 0;
                } else {
                    this.allWoManNumsPercent = 100;
                }
            } else {
                if (this.allWoManNums === 0) {
                    this.allManNumsPercent = 100;
                    this.allWoManNumsPercent = 0;
                }
            }
            if (this.allManNums !== 0 && this.allWoManNums !== 0) {
                this.allManNumsPercent = Math.round(this.allManNums / (this.allManNums + this.allWoManNums) * 100);
                this.allWoManNumsPercent = 100 - this.allManNumsPercent;
            }

            let data = {
                curDay: [
                    {sex: 0, nums: this.curDayManNums, sexDesc: "男", percent: this.curDayManNumsPercent + "%"},
                    {sex: 1, nums: this.curDayWoManNums, sexDesc: "女", percent: this.curDayWoManNumsPercent + "%"},
                ],
                all: [
                    {sex: 0, nums: this.allManNums, sexDesc: "男", percent: this.allManNumsPercent + "%"},
                    {sex: 1, nums: this.allWoManNums, sexDesc: "女", percent: this.allWoManNumsPercent + "%"},
                ],
            };
            this.curDay = [
                [
                    {
                        value: data.curDay[0].nums,
                        name: "男性",
                        manPercent: data.curDay[0].percent,
                    },
                    {
                        value: data.curDay[1].nums,
                        name: "女性",
                        manPercent: data.curDay[1].percent,
                    },
                ],
            ];
            this.allDay = [
                [
                    {
                        value: data.all[0].nums,
                        name: "男性",
                        manPercent: data.all[0].percent,
                    },
                    {
                        value: data.all[1].nums,
                        name: "女性",
                        manPercent: data.all[1].percent,
                    },
                ],
            ];
            this.drawcurDay();
            this.drawallDay();
        },
    },
};
</script>
<style lang="less">
#gender-propportion {
    width: 100%;
    height: 6rem;

    background: rgba(96, 118, 173, 0.06);
    border-radius: 2px;
}

.gender-propportioon {
    width: 90%;
    height: 5rem;
    padding: 0.2rem;

    .gender-propportion-title {
        width: 100%;
        height: 0.5rem;
        line-height: 0.5rem;
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(51, 66, 101, 1);

        .gender-propportion-title-icon {
            display: inline-block;
            width: 18px;
            height: 18px;
            background: url(../../assets/homeImg/left3.png) no-repeat;
            background-size: 100% 100%;
        }

        .gender-propportion-title-text {
            display: inline-block;
            margin-left: 15px;
            width: 100%;
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 800;
            color: rgba(162, 164, 206, 1);
            //   line-height: 35px;
        }
    }

    .gender-propportion-content {
        width: 100%;
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;

        #draw-pie-left,
        #draw-pie-right {
            width: 50%;
            height: 90%;
            //   background-color: chocolate;
        }

        span {
            width: 0px;
            height: 60%;
            border: 1px solid rgba(51, 66, 101, 1);
        }
    }
}
</style>

