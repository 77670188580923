<template>
    <div id="age-infod">
        <div class="peopel-time-search">
            <div class="peopelTimeSearch-left" @click="searchSevenOrFifteen">
                <div
                        class="peopel-time-lastseven"
                        :id="activeTime === 'peopel-time-lastseven' ? 'active-time' : ''">最近7天
                </div>
                <div
                        class="peopel-time-lastfifteen"
                        :id="activeTime === 'peopel-time-lastfifteen' ? 'active-time' : ''">本月
                </div>
                <div class="peopel-time-all" :id="activeTime === 'peopel-time-all' ? 'active-time' : ''">全部</div>
            </div>
        </div>
        <div class="drawEcharts">
            <div id="draw-column"></div>
        </div>
    </div>
</template>
<script>
import {getAgeInfoData} from "@/api/api";
import {column} from "../charts/column.js";
import router from "@/router";

export default {
    data() {
        return {
            activeTime: "peopel-time-lastseven",
            startTime: "",
            endTime: "",
            ageInforData: [],
        };
    },
    created() {
        // this.testGetData();
    },
    mounted() {
        document.getElementById("export").style.display = 'show';
        //document.getElementById("export").style.display='none';
        const params = new URLSearchParams();
        params.append("token", localStorage.getItem("singleToken"));
        params.append("rangeDays", 7);

        this.getAgeData(params);
        this.hideExport();
    },
    methods: {
        hideExport() {
            document.getElementById("export").style.display = "none";
        },
        drawColumn() {
            column(
                document.getElementById("draw-column"),
                this.ageInforData,
                "年龄段信息"
            );
        },
        searchSevenOrFifteen(e) {
            console.log(e.target.className);

            this.activeTime = e.target.className;
            const params = new URLSearchParams();

            let timedata = e.target.className;
            if (timedata === "peopel-time-lastfifteen") {
                params.append("token", localStorage.getItem("singleToken"));
                params.append("rangeDays", -2);
            } else if (timedata === "peopel-time-lastseven") {
                params.append("token", localStorage.getItem("singleToken"));
                params.append("rangeDays", 7);
            } else {
                params.append("token", localStorage.getItem("singleToken"));
                params.append("rangeDays", "");
            }
            this.getAgeData(params);
        },
        getAgeData(params) {
            getAgeInfoData(params).then((res) => {
                if (res.code === "API200") {
                    this.ageDataDeal(res.data.list)
                } else if (res.code === "API300") {
                    router.push("/");
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        ageDataDeal(list) {
            this.ageInforData = [];
            for (let i in list) {
                this.ageInforData.push([list[i].levelDesc, list[i].nums]);
            }
            this.drawColumn();
        },
    },
};
</script>
<style lang="less">
#age-infod {
    width: 100%;
    height: 5.8rem;
    padding: 0.1rem;

    .drawEcharts {
        width: 10.2rem;
        height: 6.8rem;
        margin-top: 20px;

        #draw-column {
            margin: auto;
            width: 100%;
            height: 5.6rem;
            background-color: rgba(96, 118, 173, 0.06);
            padding-top: 0.1rem;
        }
    }

    .peopel-time-search {
        width: 100%;
        height: 0.4rem;
        display: flex;
        justify-content: space-between;

        .peopelTimeSearch-left {
            display: flex;

            div {
                width: 1.5rem;
                height: 0.4rem;
                line-height: 0.4rem;
                text-align: center;
                background: rgba(16, 28, 55, 1);
                color: rgba(162, 164, 206, 1);
                border: 1px solid rgba(96, 118, 173, 1);
                border-radius: 2px;
            }

            :nth-child(2),
            :nth-child(3) {
                margin-left: 10px;
            }

            #active-time {
                background: rgba(11, 22, 48, 1);
                color: rgba(0, 216, 255, 1);
                border: 1px solid rgba(96, 118, 173, 1);
                border-radius: 2px;
            }
        }

        .peopelTimeSearch-right {
            display: flex;
            justify-content: space-around;
            width: 4.5rem;
            display: flex;
            justify-content: space-evenly;
            align-items: center;

            .peopelTimeSearch-time {
                width: 3.3rem;
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                    width: 0.3rem;
                    color: rgba(162, 164, 206, 1);
                }

                .el-input__inner {
                    width: 1.5rem;
                    height: 0.4rem;
                    line-height: 0.4rem;
                    text-align: center;
                    background: rgba(16, 28, 55, 1);
                    color: rgba(162, 164, 206, 1);
                    border: 1px solid rgba(96, 118, 173, 1);
                    border-radius: 2px;

                    input {
                        font-size: 0.16rem;
                        color: rgba(162, 164, 206, 1);
                    }
                }
            }
        }
    }
}
</style>

