<template>
    <div class="login">
        <div class="header">
            <h1>二代步道</h1>
            <div class="header-date">
                <div>
                    <span>{{ nowDate }}</span>
                </div>
            </div>
        </div>
        <div class="login-content">
            <div class="content-back">
                <div class="login-username">
                    <span></span>
                    <el-input v-model="userName" placeholder="请输入用户名" focus="usernameFocus"></el-input>
                </div>
                <div class="login-password">
                    <span></span>
                    <el-input v-model="passWord" show-password placeholder="请输入密码"></el-input>
                </div>
                <div class="login-code">
                    <span></span>
                    <el-input v-model="yanzhengCode" placeholder="请输入验证码"></el-input>
                    <img :src="'data:image/png;base64,'+authCode" alt @click="getRunAuthCode()"/>
                </div>
                <div class="login-button" @click="goLogin()">
                    <span>登录</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// @ is an alias to /src
import Header from "../components/iframe/Header.vue";
import {loginAction, getAuthCode} from "../api/api.js";

export default {
    data() {
        return {
            userName: "",
            passWord: "",
            yanzhengCode: "",
            secretKey: "",
            authCode: "",
            nowDate: "",
        };
    },
    name: "Login",
    components: {
        Header,
    },
    created() {
    },
    mounted() {
        this.getRunAuthCode();
        let timerId = setInterval(this.showTime, 1000);
    },
    methods: {
        goLogin() {
            const params = new URLSearchParams();
            params.append("loginName", this.userName);
            params.append("credential", this.passWord);
            params.append("authCode", this.yanzhengCode);
            params.append("secretKey", this.secretKey);

            loginAction(params)
                .then((res) => {
                    if (res.code === "API200") {
                        let userLevel = res.data.userLevel;
                        let token = res.data.token;
                        let itemId = res.data.itemId;
                        // 单步道
                        if (userLevel === 1) {
                            this.$router.push("/direct/"+itemId+"/"+token);
                        }
                        // 汇总账号或总账号
                        else {
                            localStorage.setItem("token", token);
                            this.$router.push("/overallstatus?permission=1");
                        }
                    } else {
                        this.$message.error(res.msg);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getRunAuthCode() {
            getAuthCode().then((res) => {
                if (res.code === "API200") {
                    this.authCode = res.data.captchaBase64;
                    this.secretKey = res.data.secretKey;
                }
            });
        },
        showTime() {
            let date = new Date();
            let weekText = "";
            switch (date.getDay()) {
                case 0:
                    weekText = "星期日";
                    break;
                case 1:
                    weekText = "星期一";
                    break;
                case 2:
                    weekText = "星期二";
                    break;
                case 3:
                    weekText = "星期三";
                    break;
                case 4:
                    weekText = "星期四";
                    break;
                case 5:
                    weekText = "星期五";
                    break;
                case 5:
                    weekText = "星期六";
                    break;
            }
            let yue = date.getMonth() + 1;
            let newDate = `${date.getFullYear()}年${
                yue === 13 ? "1" : yue
            }月${date.getDate()}日 ${weekText} ${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}: ${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}: ${
                date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()
            }`;
            this.nowDate = newDate;
        },
    },
};
</script>
<style scoped lang="less">
@import "../assets/css/Login.less";
</style>
