<template>
    <div id="user-reg">
        <div class="reg-title">
            <div>用户姓名：</div>
            <div>
                <el-input v-model="title" placeholder="请输入用户姓名"></el-input>
            </div>
        </div>
        <div class="reg-title">
            <div>用户电话：</div>
            <div>
                <el-input v-model="title" placeholder="请输入用户电话"></el-input>
            </div>
        </div>
        <div class="reg-title">
            <div>所在区域：</div>
            <div>
                <el-input v-model="title" placeholder="请输入所在区域"></el-input>
            </div>
        </div>
        <div class="reg-title">
            <div>所选项目：</div>
            <div>
                <el-input v-model="title" placeholder="请输入所选项目"></el-input>
            </div>
        </div>
        <div class="reg-content">
            <button @click="releaseCon()">发布</button>
        </div>

    </div>
</template>
<script>
import {getPublishInfo} from "../../api/api.js";
import router from "@/router";

export default {
    data() {
        return {
            title: "",
            content: "",
        };
    },
    mounted() {
        this.hideExport();
    },
    methods: {
        hideExport() {
            document.getElementById("export").style.display = "none";
        },
        releaseCon() {
            const params = new URLSearchParams();
            params.append("token", localStorage.getItem("singleToken"));
            params.append("title", this.title);
            params.append("content", this.content);

            getPublishInfo(params).then((res) => {
                if (res.code === "API200") {
                    this.$message({
                        message: "发布成功",
                        type: "success",
                    });
                    this.title = '';
                    this.content = '';
                } else if (res.code === "API300") {
                    router.push("/");
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
    },
};
</script>
<style lang="less">
#user-reg {
    position: relative;
    height: 5.16rem;
    padding: 30px;
    background: rgba(95, 117, 173, 0.06);
    border-radius: 2px;
    display: flex;
    flex-direction: column;
}

.reg-title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    :nth-child(1) {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 800;
        color: #A2A4CE;
        line-height: 35px;
    }

    :nth-child(2) {
        flex: 1;
        height: 50px;
        background: #0D1934;
        border: 1px solid #27417b;

        .el-input__inner {
            background: none;
            border: none;
            height: 50px;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #afb1cf;
            line-height: 50px;
        }
    }
}

.reg-content {
    position: relative;

    button {
        position: absolute;
        right: 0;
        width: 1.06rem;
        height: 0.4rem;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 800;
        color: rgba(255, 255, 255, 1);
        line-height: 0.36rem;
        border: 1px solid #27417b;
        border-radius: 2px;
        background: url(../../assets/homeImg/daochu_back.png) no-repeat;
        background-size: 100% 100%;
    }
}
</style>
