<template>
    <iframe width="100%" height="620px" src="https://sports.sina.cn/running/index.d.html?vt=4&pos=10"></iframe>
</template>

<script>
export default {
    data() {
        return {
        };
    },
    created() {
    },
    mounted() {
        this.hideExport();
    },
    methods: {
        hideExport() {
            document.getElementById("export").style.display = "none";
        },
    },
};
</script>