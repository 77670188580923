<template>
    <div id="footin-for">
        <div class="footin-top">
            <div class="footin-top-title">
                <span class="footin-top-title-icon"></span>
                <span class="footin-top-title-text">步道信息</span>
            </div>
            <div class="footin-top-infor">
                <div>
                    <span>步道名称：{{ footName }}</span>
                </div>
                <div>
                    <span>步道长度：{{ footLength }} 米</span>
                </div>
                <div>
                    <span>步道简介：{{ footIntro }}</span>
                </div>
                <div>
                    <span>创建时间：{{ footCreateTime }}</span>
                </div>
            </div>
        </div>
        <div class="footin-bottom">
            <div class="footin-bottom-title">
                <span class="footin-bottom-title-icon"></span>
                <span class="footin-bottom-title-text">步道图片</span>
            </div>
            <div class="footin-bottom-infor">
                <!--                <div><img :src="'data:image/png;base64,'+footImg"/></div>-->

                <el-upload
                        class="avatar-uploader"
                        action="http://39.108.83.46:8097/bi/image/upload"
                        :show-file-list="false"
                        :data="file1Data"
                        :on-remove="deleteImg"
                        :on-success="handleAvatar1Success"
                        :before-upload="beforeAvatarUpload"
                >
                    <img id="img1" v-if="position1Img" :src="position1Img" class="avatar" alt=""/>
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
<!--                    <div slot="tip" class="el-upload__tip">建议尺寸：700*800px</div>-->
                </el-upload>
                <el-button id="btn1" v-if="position1Img" size="small" type="danger" @click="deleteImg(1)">删除图片1</el-button>
            </div>
            <div class="footin-bottom-infor">
                <!--                <div><img :src="'data:image/png;base64,'+footImg"/></div>-->

                <el-upload
                    class="avatar-uploader"
                    action="http://39.108.83.46:8097/bi/image/upload"
                    :show-file-list="false"
                    :data="file2Data"

                    :on-success="handleAvatar2Success"
                    :before-upload="beforeAvatarUpload"
                >
                    <img id="img2" v-if="position2Img" :src="position2Img" class="avatar" alt=""/>
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
<!--                    <div slot="tip" class="el-upload__tip">建议尺寸：700*800px</div>-->
                </el-upload>
                <el-button id="btn2" v-if="position2Img" size="small" type="danger" @click="deleteImg(2)">删除图片2</el-button>
            </div>
            <div class="footin-bottom-infor">
                <!--                <div><img :src="'data:image/png;base64,'+footImg"/></div>-->

                <el-upload
                    class="avatar-uploader"
                    action="http://39.108.83.46:8097/bi/image/upload"
                    :show-file-list="false"
                    :data="file3Data"

                    :on-success="handleAvatar3Success"
                    :before-upload="beforeAvatarUpload"
                >
                    <img id="img3" v-if="position3Img" :src="position3Img" class="avatar" alt=""/>
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
<!--                    <div slot="tip" class="el-upload__tip">建议尺寸：700*800px</div>-->
                </el-upload>
                <el-button id="btn3" v-if="position3Img" size="small" type="danger" @click="deleteImg(3)">删除图片3</el-button>
            </div>
            <div class="footin-bottom-infor">
                <!--                <div><img :src="'data:image/png;base64,'+footImg"/></div>-->

                <el-upload
                    class="avatar-uploader"
                    action="http://39.108.83.46:8097/bi/image/upload"
                    :show-file-list="false"
                    :data="file4Data"

                    :on-success="handleAvatar4Success"
                    :before-upload="beforeAvatarUpload"
                >
                    <img id="img4" v-if="position4Img" :src="position4Img" class="avatar" alt=""/>
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
<!--                    <div slot="tip" class="el-upload__tip">建议尺寸：700*800px</div>-->
                </el-upload>
                <el-button id="btn4" v-if="position4Img" size="small" type="danger" @click="deleteImg(4)">删除图片4</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import {getFootInfo, getItemImg, getPeopleData, deleteImgByItemIdAndPosition} from "@/api/api";
import router from "@/router";

export default {
    data() {
        return {
            footName: '',
            footLength: '',
            footIntro: '',
            footCreateTime: '',
            footImg: '',
            position1Img: '',
            position2Img: '',
            position3Img: '',
            position4Img: '',
            file1Data: {
                token: null,
                position: 1
            },
            file2Data: {
                token: null,
                position: 2
            },
            file3Data: {
                token: null,
                position: 3
            },
            file4Data: {
                token: null,
                position: 4
            }
        };
    },
    mounted() {
        document.getElementById("export").style.display = 'show';
        //document.getElementById("export").style.display='none';
        let token = localStorage.getItem("singleToken");
        const params = new URLSearchParams();
        params.append("token", token);
        this.file1Data.token = token;
        this.file2Data.token = token;
        this.file3Data.token = token;
        this.file4Data.token = token;

        getFootInfo(params).then((res) => {
            if (res.code === "API200") {
                this.footName = res.data.itemName;
                this.footLength = res.data.itemLength;
                this.footIntro = res.data.itemDesc;
                this.footCreateTime = res.data.itemCreateTime;
                this.footImg = res.data.itemImageBase64;
            } else if (res.code === "API300") {
                router.push("/");
            } else {
                this.$message.error(res.msg);
            }
        })

        getItemImg(params).then((res) => {
            if (res.code === "API200") {
                let itemImages = res.data.itemImages;
                for (let i in itemImages) {
                    let position = itemImages[i].position;
                    let itemImage = itemImages[i].itemImage;
                    if (position === 1) {
                        this.position1Img = itemImage;
                    }
                    else if (position === 2) {
                        this.position2Img = itemImage;
                    }
                    else if (position === 3) {
                        this.position3Img = itemImage;
                    }
                    else if (position === 4) {
                        this.position4Img = itemImage;
                    }
                }
            } else if (res.code === "API300") {
                router.push("/");
            } else {
                this.$message.error(res.msg);
            }
        })

        this.hideExport();

    },
    methods: {
        hideExport() {
            document.getElementById("export").style.display = "none";
        },
        // 图片上传成功的操作
        handleAvatar1Success(res, file) {
            if (res.code === "API200") {
                this.position1Img = URL.createObjectURL(file.raw)
            } else {
                this.$message.error(res.msg)
            }
        },
        handleAvatar2Success(res, file) {
            if (res.code === "API200") {
                this.position2Img = URL.createObjectURL(file.raw)
            } else {
                this.$message.error(res.msg)
            }
        },
        handleAvatar3Success(res, file) {
            if (res.code === "API200") {
                this.position3Img = URL.createObjectURL(file.raw)
            } else {
                this.$message.error(res.msg)
            }
        },
        handleAvatar4Success(res, file) {
            if (res.code === "API200") {
                this.position4Img = URL.createObjectURL(file.raw)
            } else {
                this.$message.error(res.msg)
            }
        },
        // 图片上传前的判断
        beforeAvatarUpload(file) {
            let permission = this.$route.query.permission;
            if (permission === undefined) {
                this.$message.error('您的账号无上传图片的权限')
                return false;
            }
            const isLt1M = file.size / 1024 / 1024
            if (isLt1M > 30) {
                this.$message.error('上传头像图片大小不能超过30MB')
                return false;
            }
            return true;
        },
        // 删除图片
        deleteImg(position) {
            let token = localStorage.getItem("singleToken");
            const params = new URLSearchParams();
            params.append("token", token);
            params.append("position", position);
            this.deleteImgByItemIdAndPositionFun(params);
        },
        deleteImgByItemIdAndPositionFun(params) {
            deleteImgByItemIdAndPosition(params).then((res) => {
                if (res.code === "API200") {
                    this.$message.success("删除成功")
                    let position = params.get("position");
                    console.info(position)
                    if (position === "1") {
                        this.position1Img = "";
                        // document.getElementById("img1").remove();
                        // document.getElementById("btn1").remove();
                    }
                    else if (position === "2") {
                        this.position2Img = "";
                        // document.getElementById("img2").remove();
                        // document.getElementById("btn2").remove();
                    }
                    else if (position === "3") {
                        this.position3Img = "";
                        // document.getElementById("img3").remove();
                        // document.getElementById("btn3").remove();
                    }
                    else if (position === "4") {
                        this.position4Img = "";
                        // document.getElementById("img4").remove();
                        // document.getElementById("btn4").remove();
                    }
                } else {
                    this.$message.error(res.msg);
                }
            });
        }
    }
};
</script>
<style lang="less">
#footin-for {
    width: 100%;
    height: 6rem;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footin-top-title,
.footin-bottom-title {
    width: 90%;
    height: 25%;
    margin: auto;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #334265;

    .footin-top-title-icon {
        background: url(../../assets/homeImg/left7.png) no-repeat;
    }

    .footin-bottom-title-icon {
        background: url(../../assets/homeImg/left7_2.png) no-repeat;
    }

    .footin-top-title-icon,
    .footin-bottom-title-icon {
        display: inline-block;
        width: 18px;
        height: 18px;

        background-size: 100% 100%;
    }

    .footin-top-title-text,
    .footin-bottom-title-text {
        display: inline-block;
        margin-left: 15px;
        width: 75px;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 800;
        color: rgba(162, 164, 206, 1);
    }
}

.footin-top {
    width: 100%;
    height: 40%;
    background-color: rgba(96, 118, 173, 0.06);

    .footin-top-infor {
        width: 90%;
        height: 75%;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        > div {
            width: 90%;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #a2a4ce;

            > span {
                width: 100%;
                line-height: 35px;
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}

.footin-bottom {
    width: 100%;
    height: 55%;
    background-color: rgba(96, 118, 173, 0.06);

    .footin-bottom-infor {
        width: 25%;
        height: 60%;
        margin: auto;
        display: flex;
        float: left;
        justify-content: space-around;

        > div {
            background-color: #32436b;
            //margin: 20px 0;
        }

        :nth-child(1),
        :nth-child(3) {
            width: 100%;
            height: 100%;

        }

        :nth-child(2) {
            width: 40%;
        }
    }
}

.avatar-uploader-icon {
    border: 1px dashed #d9d9d9 !important;
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9 !important;
    border-radius: 6px !important;
    position: relative !important;
    overflow: hidden !important;
}

.avatar-uploader .el-upload:hover {
    border: 1px dashed #d9d9d9 !important;
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 300px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 300px;
    height: 178px;
    display: block;
}
</style>
