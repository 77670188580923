let echarts = require("echarts");
export const line = (obj, data, title) => {
    console.log(data);
    let chart = echarts.init(obj);
    let option = {
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: ['综合评分', '体重', '体格指数', '总水分', '蛋白质', '无机盐', '脂肪重量', '去脂体重', '体脂肪率',
                '内脏脂肪', '体水分率', '骨骼肌率', '细胞内液', '细胞外液', '骨矿含量', '生理年龄', '基础代谢', '心率', '体型'],
            textStyle: {
                color: '#DDDFFB'//字体颜色
            },

        },
        grid: {
            left: '2%',
            right: '2%',
            bottom: '1%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: [],
            axisLabel: {
                show: true,
                textStyle: {
                    color: '#DDDFFB'
                }
            }
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                show: true,
                textStyle: {
                    color: '#DDDFFB'
                }
            }
        },
        series: [
            {
                name: '综合评分',
                type: 'line',
                data: []
            },
            {
                name: '体重',
                type: 'line',
                data: []
            },
            {
                name: '体格指数',
                type: 'line',
                data: []
            },
            {
                name: '总水分',
                type: 'line',
                data: []
            },
            {
                name: '蛋白质',
                type: 'line',
                data: []
            },
            {
                name: '无机盐',
                type: 'line',
                data: []
            },
            {
                name: '脂肪重量',
                type: 'line',
                data: []
            },
            {
                name: '去脂体重',
                type: 'line',
                data: []
            },
            {
                name: '体脂肪率',
                type: 'line',
                data: []
            },
            {
                name: '内脏脂肪',
                type: 'line',
                data: []
            },
            {
                name: '体水分率',
                type: 'line',
                data: []
            },
            {
                name: '骨骼肌率',
                type: 'line',
                data: []
            },
            {
                name: '细胞内液',
                type: 'line',
                data: []
            },
            {
                name: '细胞外液',
                type: 'line',
                data: []
            },
            {
                name: '骨矿含量',
                type: 'line',
                data: []
            },
            {
                name: '生理年龄',
                type: 'line',
                data: []
            },
            {
                name: '基础代谢',
                type: 'line',
                data: []
            },
            {
                name: '心率',
                type: 'line',
                data: []
            },
            {
                name: '体型',
                type: 'line',
                data: []
            }
        ]
    };

    for (var i in data) {
        option.xAxis.data.push(data[i].testDate);
        option.series[0].data.push(data[i].compositeScore);
        option.series[1].data.push(data[i].weight);
        option.series[2].data.push(data[i].bmi);
        option.series[3].data.push(data[i].tbw);
        option.series[4].data.push(data[i].protein);
        option.series[5].data.push(data[i].inorganicSalt);
        option.series[6].data.push(data[i].tbf);
        option.series[7].data.push(data[i].ffm);
        option.series[8].data.push(data[i].tbr);
        option.series[9].data.push(data[i].vfi);
        option.series[10].data.push(data[i].tbwr);
        option.series[11].data.push(data[i].sm);
        option.series[12].data.push(data[i].icw);
        option.series[13].data.push(data[i].ecw);
        option.series[14].data.push(data[i].bmc);
        option.series[15].data.push(data[i].bda);
        option.series[16].data.push(data[i].bmr);
        option.series[17].data.push(data[i].heartRate);
        option.series[18].data.push(data[i].somatotype);
    }

    chart.setOption(option);
};
