<template>
    <div id="release-infor">
        <div class="relese-title">
            <div>标题：</div>
            <div>
                <el-input v-model="title" placeholder="请输入标题"></el-input>
            </div>
        </div>
        <div class="relese-content">
            <div>内容：</div>
            <div>
                <el-input type="textarea" :rows="7" placeholder="请输入内容" v-model="content"></el-input>
            </div>
            <button @click="releaseCon()">发布</button>
        </div>
    </div>
</template>
<script>
import {getPublishInfo} from "../../api/api.js";
import router from "@/router";

export default {
    data() {
        return {
            title: "",
            content: "",
        };
    },
    mounted() {
        this.hideExport();
    },
    methods: {
        hideExport() {
            document.getElementById("export").style.display = "none";
        },
        releaseCon() {
            const params = new URLSearchParams();
            params.append("token", localStorage.getItem("singleToken"));
            params.append("title", this.title);
            params.append("content", this.content);

            getPublishInfo(params).then((res) => {
                if (res.code === "API200") {
                    this.$message({
                        message: "发布成功",
                        type: "success",
                    });
                    this.title = '';
                    this.content = '';
                } else if (res.code === "API300") {
                    router.push("/");
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
    },
};
</script>
<style lang="less">
#release-infor {
    height: 5.16rem;
    padding: 30px;
    margin-top: 50px;
    background: rgba(95, 117, 173, 0.06);
    border-radius: 2px;
    display: flex;
    flex-direction: column;
}

.relese-title {
    display: flex;
    align-items: center;

    :nth-child(1) {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 800;
        color: #a1a3cd;
        line-height: 35px;
    }

    :nth-child(2) {
        flex: 1;
        height: 50px;
        background: #0d1933;
        border: 1px solid #27417b;

        .el-input__inner {
            background: none;
            border: none;
            height: 50px;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #afb1cf;
            line-height: 50px;
        }
    }
}

.relese-content {
    //   padding: 0 30px;
    position: relative;
    margin-top: 30px;
    display: flex;

    :nth-child(1) {
        margin-top: -4px;
        line-height: 50px;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 800;
        color: #a1a3cd;
    }

    :nth-child(2) {
        flex: 1;
        background: #0d1933;
        border: 1px solid #27417b;

        .el-textarea__inner {
            width: 100%;
            background: none;
            border: none;
            height: 100%;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #afb1cf;
            line-height: 50px;

            //   background: #0d1934;
            //   border: 1px solid #27417b;
        }
    }

    button {
        position: absolute;
        right: 0.5rem;
        bottom: 0.5rem;
        width: 1.06rem;
        height: 0.4rem;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 800;
        color: rgba(255, 255, 255, 1);
        line-height: 0.36rem;
        border: 1px solid #27417b;
        border-radius: 2px;
        background: url(../../assets/homeImg/daochu_back.png) no-repeat;
        background-size: 100% 100%;
    }

    // .el-message--success {
    //   background-color: #6076ad;
    //   border: 1px solid #27417b;
    // }
}
</style>
