<template>
    <div class="header">
        <h1 :class="titleName ? 'normal' : 'nomore'">{{ title }}</h1>
        <div class="header-date">
            <div>
                <span>{{ nowDate }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import {getglobalInfo} from "../../api/api.js";
import router from "@/router";

export default {
    data() {
        return {
            titleName: true,
            nowDate: "",
            title: "",
        };
    },
    mounted() {
        /* 获取时间 */
        let timerId = setInterval(this.showTime, 1000);
        /* 获取title */
        const params = new URLSearchParams();
        params.append("token", localStorage.getItem("singleToken"));

        getglobalInfo(params).then((res) => {
            if (res.code === "API200") {
                this.title = res.data.itemName;
                if (this.title.length > 7) {
                    this.titleName = false;
                }
            } else if (res.code === "API300") {
                router.push("/");
            } else {
                this.$message.error(res.msg);
            }
        });
    },
    methods: {
        showTime() {
            let date = new Date();
            let weekText = "";
            switch (date.getDay()) {
                case 0:
                    weekText = "星期日";
                    break;
                case 1:
                    weekText = "星期一";
                    break;
                case 2:
                    weekText = "星期二";
                    break;
                case 3:
                    weekText = "星期三";
                    break;
                case 4:
                    weekText = "星期四";
                    break;
                case 5:
                    weekText = "星期五";
                    break;
                case 5:
                    weekText = "星期六";
                    break;
            }
            let yue = date.getMonth() + 1;
            let newDate = `${date.getFullYear()}年${yue === 13 ? '1' : yue}月${date.getDate()}日 ${weekText} ${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}: ${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}: ${
                date.getSeconds() < 10
                    ? "0" + date.getSeconds()
                    : date.getSeconds()
            }`;
            this.nowDate = newDate;
        },
    },
};
</script>
<style lang="less">
.header {
    width: 100%;
    height: 11%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: url(../../assets/homeImg/head_back.png) no-repeat;
    background-size: 100%;

    h1 {
        width: 5rem;
        height: 1rem;
        line-height: 1rem;
        text-align: center;
        font-weight: 400;
        margin-left: 4%;
        font-family: Microsoft YaHei;
        color: #FFFFFF;
        -webkit-text-stroke: 1px #BBBBBB;
        text-stroke: 1px #BBBBBB;
        background: linear-gradient(180deg, #FFFFFF 0%, #666666 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .normal {
        font-size: 0.5rem;
    }

    .nomore {
        font-size: 0.35rem;
    }

    .header-date {
      margin-bottom: 2.5%;
      margin-right: 2.5%;
      width: 3rem;
      text-align: right;
      // height: 100%;
      > div {
        font-size: 12px;

        span {
        }
      }
    }
}
</style>
