<template>
  <v-scale-screen width="1920" height="1080" full-screen style="background: #090d26;">
    <div class="home">
        <Header></Header>
        <div class="content">
            <div class="content-left">
                <div class="content-left-background-img">
                    <ul>
                        <li
                                v-for="(item, index) in tabList"
                                :key="index"
                                :class="activeName === item.label ? 'click-after' : ''"
                                @click="handleClick(item, index)">
                            <img
                                    :src="activeNum === index ? item.picture_hover : item.picture_base"
                                    alt
                            />
                            <span>{{ item.name }}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="content-center">
                <div class="content-center-head">
                    <div class="content-detail-left">
                        <h6>当日人数</h6>
                        <p class="content-detail-today">{{ flowData.dayCount }}</p>
                    </div>
                    <div class="content-detail-center">
                        <h6>总人数</h6>
                        <p class="content-detail-all">{{ flowData.totalCount }}</p>
                    </div>
                    <div class="content-detail-right">
                        <button id="export">
                            <img src="../assets/homeImg/daochu.png" alt/>
                            导出
                        </button>
                    </div>
                </div>
                <div class="content-center-box">
                    <component :is="activeName"></component>
                </div>
            </div>
            <div class="content-right">
                <div class="content-right-background-img">
                    <div class="content-right-box">
                        <div class="content-right-address">
                            <img src="../assets/homeImg/dingwei.png" alt/>
                            <span>{{ weatherData.cityName }}</span>
                        </div>
                        <div class="content-right-weather">
                            <img src="../assets/homeImg/right1.png" alt/>
                            <span>{{ weatherData.temperature }}℃</span>
                            <span>{{ weatherData.weather }}</span>
                        </div>
                        <div class="content-right-details">
                            <div>
                                <span></span>
                                <span>{{ weatherData.humidity }}</span>
                                <span>湿度</span>
                            </div>
                            <div>
                                <span></span>
                                <span>{{ weatherData.visibility }}</span>
                                <span>可见度</span>
                            </div>
                            <div>
                                <span></span>
                                <span>{{ weatherData.windScale }}级</span>
                                <span>{{ weatherData.windDirection }}风</span>
                            </div>
                            <div>
                                <span></span>
                                <span>{{ weatherData.pm25 }}</span>
                                <span>PM2.5</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </v-scale-screen>
</template>
<script>
// @ is an alias to /src
import {getglobalInfo} from "../api/api.js";
import Header from "../components/iframe/Header.vue";
import InoutDetail from "../components/iframe/InoutDetail.vue";
import PeoPleTrend from "../components/iframe/PeoPleTrend.vue";
import GenderProportion from "../components/iframe/GenderProportion.vue";
import AgeInfor from "../components/iframe/AgeInfor.vue";
import Constitution from "../components/iframe/Constitution.vue";
import SportNum from "../components/iframe/SportNum.vue";
import FootInfor from "../components/iframe/FootInfor.vue";
import ReleaseInfor from "../components/iframe/ReleaseInfor.vue";
import UserInfo from "../components/iframe/UserInfo.vue";
import UserReg from "../components/iframe/UserReg.vue";
import Information from "../components/iframe/Information.vue";


export default {
    data() {
        return {
            activeName: "InoutDetail",
            activeNum: 0,
            homeTittle: "",
            flowData: {},
            weatherData: {},
            tabList: [
                {
                    name: "进出详情",
                    label: "InoutDetail",
                    picture_base: require("../assets/homeImg/left1.png"),
                    picture_hover: require("../assets/homeImg/left_hover1.png"),
                },
                {
                    name: "人数趋势",
                    label: "PeoPleTrend",
                    picture_base: require("../assets/homeImg/left2.png"),
                    picture_hover: require("../assets/homeImg/left_hover2.png"),
                },
                {
                    name: "性别比例",
                    label: "GenderProportion",
                    picture_base: require("../assets/homeImg/left3.png"),
                    picture_hover: require("../assets/homeImg/left_hover3.png"),
                },
                {
                    name: "年龄段信息",
                    label: "AgeInfor",
                    picture_base: require("../assets/homeImg/left4.png"),
                    picture_hover: require("../assets/homeImg/left_hover4.png"),
                },
                {
                    name: "国民体质",
                    label: "Constitution",
                    picture_base: require("../assets/homeImg/left5.png"),
                    picture_hover: require("../assets/homeImg/left_hover5.png"),
                },
                {
                    name: "运动数据",
                    label: "SportNum",
                    picture_base: require("../assets/homeImg/left6.png"),
                    picture_hover: require("../assets/homeImg/left_hover6.png"),
                },
                {
                    name: "步道信息",
                    label: "FootInfor",
                    picture_base: require("../assets/homeImg/left7.png"),
                    picture_hover: require("../assets/homeImg/left_hover7.png"),
                },
                {
                    name: "发布信息",
                    label: "ReleaseInfor",
                    picture_base: require("../assets/homeImg/left8.png"),
                    picture_hover: require("../assets/homeImg/left_hover8.png"),
                },
                {
                    name: "信息资讯",
                    label: "Information",
                    picture_base: require("../assets/homeImg/left11.png"),
                    picture_hover: require("../assets/homeImg/left11_hover.png"),
                },
                {
                    name: "用户信息",
                    label: "UserInfo",
                    picture_base: require("../assets/homeImg/left9.png"),
                    picture_hover: require("../assets/homeImg/left_hover9.png"),
                },
                {
                    name: "用户报名",
                    label: "UserReg",
                    picture_base: require("../assets/homeImg/left10.png"),
                    picture_hover: require("../assets/homeImg/left_hover10.png"),
                },

            ],
            leftHoverImgList: {
                InoutDetail: require("../assets/homeImg/left_hover1.png"),
                PeoPleTrend: require("../assets/homeImg/left_hover2.png"),
                GenderProportion: require("../assets/homeImg/left_hover3.png"),
                AgeInfor: require("../assets/homeImg/left_hover4.png"),
                Constitution: require("../assets/homeImg/left_hover5.png"),
                SportNum: require("../assets/homeImg/left_hover6.png"),
                FootInfor: require("../assets/homeImg/left_hover7.png"),
                ReleaseInfor: require("../assets/homeImg/left_hover8.png"),
                UserInfo: require("../assets/homeImg/left_hover9.png"),
                UserReg: require("../assets/homeImg/left_hover10.png"),
                Information: require("../assets/homeImg/left11_hover.png"),
            },
        };
    },
    name: "Home",
    components: {
        Header,
        InoutDetail,
        PeoPleTrend,
        GenderProportion,
        AgeInfor,
        Constitution,
        SportNum,
        FootInfor,
        ReleaseInfor,
        UserInfo,
        UserReg,
        Information
    },
    created() {
    },
    mounted() {
        const params = new URLSearchParams();
        params.append("token", localStorage.getItem("singleToken"));

        getglobalInfo(params).then((res) => {
            if (res.code === "API200") {
                this.homeTittle = res.data.itemName;
                this.flowData = res.data.flowData;
                this.weatherData = res.data.weatherData;
            } else {
                //this.$router.push("/");
                this.$message.error(res.msg);
            }
        });
    },
    methods: {
        handleClick(item, index) {
            this.activeName = item.label;
            this.activeNum = index;
        },
    },
};
</script>
<style scoped lang="less">
@import "../assets/css/Home.less";
</style>
