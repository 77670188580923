let echarts = require("echarts");
let imgDown = require('../../assets/homeImg/trend_down.png');
let imgUp = require('../../assets/homeImg/trend_up.png');
export const trend = (obj, data) => {
    let chart = echarts.init(obj);
    let item = null;
    let option = {
        title: {
            text: "人数趋势",
            left: "center",
            align: "right",
            textStyle: {
                color: "rgba(162,164,206,1)",
                fontSize: 36,
                fontWeight: 800,
            },
        },
        xAxis: {
            type: "category",
            boundaryGap: false,
            axisLine: {
                lineStyle: {
                    color: 'rgba(40,66,124,1)'
                }
            },
            axisLabel: {
                show: true,
                interval: 0,
                fontSize: 16,
                fontWeight: 400,
                color: 'rgba(222,223,251,1)',
                lineHeight: 36,
                rotate:30
            },
        },
        yAxis: {
            type: "value",
            name: "人数/人",
            nameLocation: "end",
            nameTextStyle: {
                color: 'rgba(222,223,251,1)',
                fontSize: "16px",
                fontWeight: 400,
            },
            axisLine: {
                lineStyle: {
                    color: 'rgba(40,66,124,1)'
                }
            },
            splitLine: {
                lineStyle: {
                    color: 'rgba(40,66,124,1)'
                }
            },
            axisLabel: {
                show: true,
                fontSize: 16,
                fontWeight: 400,
                color: 'rgba(222,223,251,1)',
                lineHeight: 36,
            },
        },
        series: [{
            type: "line",
            smooth: 0.5,
            symbol: (data) => {  // 这里需要做判断： 确认当前数据是上升还是下降 会有对应的 + - 图标
                if (item) {
                    if (item < data[1]) {
                        item = data[1];
                        return `image://${imgUp}`
                    } else {
                        item = data[1];
                        return `image://${imgDown}`
                    }
                } else {
                    item = data[1]
                    return `image://${imgUp}`
                }
            }, 
            symbolSize: [14, 14],
            lineStyle: {
                width: 0,
            },
            label: {
                show: true,
                position: "top",
                formatter: (val) => {
                    let value = val.value[1];
                    return `{a|${value}}`;
                },
                textStyle: {
                    align: 'center',
                    // verticalAlign: 'middle',
                    rich: {
                        a: {
                            width: 47,
                            height: 24,
                            color: 'rgba(255, 255, 255, 1)',
                            backgroundColor: "rgba(103, 136, 215, 1)",
                            opacity: 0.9,
                            borderRadius: 12,
                            // textAlign: 'center',
                            lineHeight: 24
                        },
                    },
                }

            },
            areaStyle: {
                color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0,
                        color: "#2B59E0", // 0% 处的颜色
                    },
                    {
                        offset: 1,
                        color: "white", // 100% 处的颜色
                    },
                    ],
                    global: false, // 缺省为 false
                },
            },
            emphasis: {
                lable: {
                    show: true,
                },
            },
            data: data,
        },],
    };
    chart.setOption(option);
};
