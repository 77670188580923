<template>
    <div id="national-physical">
        <div class="national-physical">
            <div class="peopelTimeSearch-left" @click="searchSevenOrFifteen">
                <div class="peopel-time-lastseven" :id="activeTime ? 'active-time' : ''">今日使用情况</div>
                <div class="peopel-time-lastfifteen" :id="!activeTime ? 'active-time' : ''">15天内使用情况</div>
            </div>
            <div class="national-physical-constitution">
                <div id="draw-pie-constitution"></div>
            </div>
        </div>
    </div>
</template>
<script>
import {getNational} from '../../api/api.js';
import {pie} from "../charts/pie.js";
import router from "@/router";

export default {
    data() {
        return {
            activeTime: true,
            level_1_value: '',
            level_2_value: '',
            level_3_value: '',
            level_4_value: '',
            level_5_value: '',
            colorList: ["#30C1D2", "#2705EF", "#2B59E0", "#1B9BE2", "#3B4F74"],
            constitutionData: [],
        };
    },
    mounted() {
        document.getElementById("export").style.display = 'show';
        //document.getElementById("export").style.display='none';
        const params = new URLSearchParams();
        params.append("token", localStorage.getItem("singleToken"));
        params.append("rangeDays", 0);
        this.getNationalData(params);
        this.hideExport();
    },
    methods: {
        hideExport() {
            document.getElementById("export").style.display = "none";
        },
        searchSevenOrFifteen(e) {
            const params = new URLSearchParams();
            if (e.target.className === "peopel-time-lastseven") {
                this.activeTime = true;
                params.append("token", localStorage.getItem("singleToken"));
                params.append("rangeDays", 0);
            } else {
                this.activeTime = false;
                params.append("token", localStorage.getItem("singleToken"));
                params.append("rangeDays", 15);
            }

            this.getNationalData(params);
        },
        getNationalData(params) {
            getNational(params).then((res) => {
                if (res.code === "API200") {
                    this.nationalDataDeal(res.data.scoreCounts);
                } else if (res.code === "API300") {
                    router.push("/");
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        nationalDataDeal(list) {
            for (let i in list) {
                if (list[i].scoreLevel === 1) {
                    this.level_1_value = list[i].nums;
                } else if (list[i].scoreLevel === 2) {
                    this.level_2_value = list[i].nums;
                } else if (list[i].scoreLevel === 3) {
                    this.level_3_value = list[i].nums;
                } else if (list[i].scoreLevel === 4) {
                    this.level_4_value = list[i].nums;
                } else if (list[i].scoreLevel === 5) {
                    this.level_5_value = list[i].nums;
                }
            }

            this.constitutionData = [
                [
                    {value: this.level_5_value, name: "85分以上", color: "#2FC1D1"},
                    {value: this.level_4_value, name: "75-80", color: "#2705EF"},
                    {value: this.level_3_value, name: "70-75", color: "#2B59DF"},
                    {value: this.level_2_value, name: "60-70", color: "#1B9BE1"},
                    {value: this.level_1_value, name: "60分以下", color: "#3B4F73"}
                ],
            ];
            this.drawPieLeft();
        },
        drawPieLeft() {
            pie(
                document.getElementById("draw-pie-constitution"),
                this.constitutionData,
                "",
                this.colorList,
                "vertical",
                true
            );
        },
    },
};
</script>
<style lang="less">
#national-physical {
    width: 7.19rem;
    height: 4.86rem;
    // margin-top: 30px;
    border-radius: 2px;
}

.national-physical {
    width: 7rem;
    height: 5.5rem;
    padding: 5%;

    .peopelTimeSearch-left {
        width: 100%;
        height: 40px;
        display: flex;

        div {
            width: 150px;
            height: 40px;
            cursor: pointer;
            line-height: 40px;
            text-align: center;
            background: rgba(16, 28, 55, 1);
            color: rgba(162, 164, 206, 1);
            border: 1px solid rgba(96, 118, 173, 1);
            border-radius: 2px;
        }

        :nth-child(2) {
            margin-left: 10px;
        }

        #active-time {
            background: rgba(11, 22, 48, 1);
            color: rgba(0, 216, 255, 1);
            border: 1px solid rgba(96, 118, 173, 1);
            border-radius: 2px;
        }
    }

    .national-physical-constitution {
        width: 9.7rem;
        height: 5.8rem;
        margin-top: 0.3rem;
        background: rgba(96, 118, 173, 0.06);
        border-radius: 2px;

        #draw-pie-constitution {
            width: 7.19rem;
            height: 4.86rem;
        }
    }
}
</style>
