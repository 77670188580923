<template>
    <div id="sport-num">
        <div class="peopel-time-search">
            <div class="peopelTimeSearch-left" @click="searchSevenOrFifteen">
                <div class="peopel-time-lastseven" :id="activeTime === 7 ? 'active-time' : ''">最近7天</div>
                <div class="peopel-time-lastfifteen" :id="activeTime === 15 ? 'active-time' : ''">最近15天</div>
            </div>
            <div class="peopelTimeSearch-right">
                <div class="sportTimeSearch-time">
                    <el-date-picker
                            v-model="chooseDay"
                            type="daterange"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :default-time="['00:00:00', '23:59:59']"
                    ></el-date-picker>
                </div>
                <button @click="checkDate()">查询</button>
            </div>
        </div>
        <div class="drawEcharts">
            <div id="drawTrend"></div>
        </div>
    </div>
</template>
<script>
import {getSportInfoData} from "@/api/api";

let echarts = require("echarts");
import {column} from "../charts/column.js";
import router from "@/router";

export default {
    data() {
        return {
            activeTime: 7,
            startTime: "",
            endTime: "",
            chooseDay: "",
            sportData: [],
            colorList: ["#2B59E0", "#00CAE2", "#3B4F74"],
        };
    },
    mounted() {
        document.getElementById("export").style.display = 'show';
        //document.getElementById("export").style.display='none';
        const params = new URLSearchParams();
        params.append("token", localStorage.getItem("singleToken"));
        params.append("rangeDays", 7);
        params.append("beginDate", "");
        params.append("endDate", "");

        this.getSportData(params);
        this.hideExport();
    },
    methods: {
        hideExport() {
            document.getElementById("export").style.display = "none";
        },
        searchSevenOrFifteen(e) {
            console.log(e.target.className);
            const params = new URLSearchParams();
            // 判断这可以请求接口
            if (e.target.className === "peopel-time-lastseven") {
                this.activeTime = 7;
                params.append("token", localStorage.getItem("singleToken"));
                params.append("rangeDays", 7);
            } else if (e.target.className === "peopel-time-lastfifteen") {
                this.activeTime = 15;
                params.append("token", localStorage.getItem("singleToken"));
                params.append("rangeDays", 15);
            }
            this.getSportData(params);
        },
        checkDate() {
            let start = `${this.chooseDay[0].getFullYear()}-${this.chooseDay[0].getMonth() + 1}-${this.chooseDay[0].getDate()}`;
            let end = `${this.chooseDay[1].getFullYear()}-${this.chooseDay[1].getMonth() + 1}-${this.chooseDay[1].getDate()}`;
            this.activeTime = -1;
            this.startTime = start;
            this.endTime = end;
            const params = new URLSearchParams();
            params.append("token", localStorage.getItem("singleToken"));
            params.append("rangeDays", -1);
            params.append("beginDate", this.startTime);
            params.append("endDate", this.endTime);

            this.getSportData(params);
        },

        getSportData(params) {
            getSportInfoData(params).then((res) => {
                if (res.code === "API200") {
                    this.sportData = res.data;
                    // let localdata = [
                    //     {
                    //         dataTime: "06月05日",
                    //         dataTotal: 1399,
                    //         distance: 4050,
                    //         kcal: 263.25,
                    //     },
                    //     {
                    //         dataTime: "06月10日",
                    //         dataTotal: 2823,
                    //         distance: 3730,
                    //         kcal: 242.45,
                    //     },
                    //     {
                    //         dataTime: "06月11日",
                    //         dataTotal: 1498,
                    //         distance: 1060,
                    //         kcal: 68.9,
                    //     },
                    //     {
                    //         dataTime: "06月28日",
                    //         dataTotal: 139,
                    //         distance: 600,
                    //         kcal: 39,
                    //     },
                    //     {
                    //         dataTime: "07月10日",
                    //         dataTotal: 293,
                    //         distance: 830,
                    //         kcal: 53.95,
                    //     },
                    // ];

                    this.dataDeal(this.sportData);
                    this.drawTrend();
                } else if (res.code === "API300") {
                    router.push("/");
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        dataDeal(data) {
            this.sportData = [];
            let dataTotalArray = [];
            let distanceArray = [];
            let kcalArray = [];
            for (let i = 0; i < data.length; i++) {
                let dataItem = data[i];
                let dataTime = dataItem.dataTime;

                let currDataTotalArray = [dataTime, dataItem.dataTotal];
                dataTotalArray.push(currDataTotalArray);

                let currDistanceArray = [dataTime, dataItem.distance];
                distanceArray.push(currDistanceArray);

                let currkcalArray = [dataTime, dataItem.kcal];
                kcalArray.push(currkcalArray);
            }

            this.sportData.push(dataTotalArray);
            this.sportData.push(distanceArray);
            this.sportData.push(kcalArray);
        },
        drawTrend() {
            // column(document.getElementById('drawTrend'), this.sportData, '运动数据', )
            let charts = echarts.init(document.getElementById("drawTrend"));
            let option = {
                title: {
                    text: "运动数据",
                    left: "center",
                    align: "right",
                    textStyle: {
                        color: "rgba(162,164,206,1)",
                        fontSize: 36,
                        fontWeight: 800,
                    },
                },
                // legend: {},
                xAxis: {
                    type: "category",
                    axisLabel: {
                        show: true,
                        fontSize: 16,
                        fontWeight: 400,
                        color: "rgba(222,223,251,1)",
                        lineHeight: 36,
                    },
                    axisLine: {
                        lineStyle: {
                            color: "rgba(40,66,124,1)",
                        },
                    },
                    splitLine: {
                        lineStyle: {
                            color: "rgba(40,66,124,1)",
                        },
                    },
                },
                yAxis: {
                    type: "value",
                    name: "运动量",
                    nameLocation: "end",
                    nameTextStyle: {
                        color: "rgba(222,223,251,1)",
                        fontSize: "16px",
                        fontWeight: 400,
                    },
                    splitNumber: 10,
                    splitLine: {
                        lineStyle: {
                            color: "rgba(40,66,124,1)",
                        },
                    },
                    axisLine: {
                        lineStyle: {
                            color: "rgba(40,66,124,1)",
                        },
                    },
                    axisLabel: {
                        show: true,
                        fontSize: 16,
                        fontWeight: 400,
                        color: "rgba(222,223,251,1)",
                        lineHeight: 36,
                    },
                },
                series: [],
            };
            let dataList = this.sportData.map((item, index) => {
                option.series.push({
                    type: "bar",
                    stack: "sport",
                    data: item,
                    barWidth: 40,
                    itemStyle: {
                        color: this.colorList[index],
                    },
                });
            });
            charts.setOption(option);
        },
    },
};
</script>
<style lang="less">
#sport-num {
    width: 100%;
    height: 5.8rem;
    padding: 0.1rem 0;

    .drawEcharts {
        width: 100%;
        height: 100%;
        margin-top: 20px;

        #drawTrend {
            margin: auto;
            width: 100%;
            height: 5.6rem;
            background-color: rgba(96, 118, 173, 0.06);
            padding-top: 0.1rem;
        }
    }

    .peopel-time-search {
        width: 100%;
        height: 0.4rem;
        display: flex;
        justify-content: space-between;

        .peopelTimeSearch-left {
            display: flex;

            div {
                width: 1.5rem;
                height: 0.4rem;
                line-height: 0.4rem;
                text-align: center;
                background: rgba(16, 28, 55, 1);
                color: rgba(162, 164, 206, 1);
                border: 1px solid rgba(96, 118, 173, 1);
                border-radius: 2px;
            }

            :nth-child(2) {
                margin-left: 10px;
            }

            #active-time {
                background: rgba(11, 22, 48, 1);
                color: rgba(0, 216, 255, 1);
                border: 1px solid rgba(96, 118, 173, 1);
                border-radius: 2px;
            }
        }

        .peopelTimeSearch-right {
            display: flex;
            justify-content: space-around;
            width: 4.5rem;
            display: flex;
            justify-content: space-evenly;
            align-items: center;

            .sportTimeSearch-time {
                width: 3.3rem;
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                    width: 0.3rem;
                    color: rgba(162, 164, 206, 1);
                }

                .el-input__inner {
                    width: 3.5rem;
                    height: 0.42rem;
                    line-height: 0.42rem;
                    text-align: center;
                    background: rgba(16, 28, 55, 1);
                    color: rgba(162, 164, 206, 1);
                    border: 1px solid rgba(96, 118, 173, 1);
                    border-radius: 2px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .el-icon-date:before {
                        content: "";
                    }

                    .el-input__icon:after {
                        content: "";
                    }

                    .el-date-editor {
                        height: 0;
                    }

                    .el-range-separator {
                        height: 100%;
                        // display: none;
                        // line-height: 0.42rem;
                    }

                    input {
                        background: none;
                        font-size: 18px;
                        color: rgba(162, 164, 206, 1);
                    }
                }

                .el-date-range-picker {
                    background: red;
                }

                .el-picker-panel__body {
                    background: red;
                    // background: rgba(96, 118, 173, 0.06);
                }
            }

            button {
                width: 1.06rem;
                height: 0.4rem;
                font-size: 18px;
                font-family: PingFang SC;
                font-weight: 800;
                color: rgba(255, 255, 255, 1);
                line-height: 0.36rem;
                background: linear-gradient(90deg, #253379, #4D7B8F);
                border: 1px solid #27417B;
                border-radius: 2px;
                background: url(../../assets/homeImg/daochu_back.png) no-repeat;
                background-size: 100% 100%;
            }
        }
    }
}
</style>
