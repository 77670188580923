let echarts = require("echarts");
export const pie = (obj, data, time, color, orient, type) => {
    let chart = echarts.init(obj);
    let option = {
        tooltip: {
            show: false,
            trigger: "item",
            formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        legend: {
            icon: "roundRect",
            orient: orient,
            iconCorlor: 'red',
            formatter: function (name) {
                let proportion = "";
                let iconLenght = '   ';
                if (type) {
                    return `{a|${name}}`;
                } else {

                    // 注意取得是哪一段数据
                    for (let i of data[0]) {
                        if (name === i.name) {
                            proportion = i.manPercent;
                        }
                    }
                    return `{a|${name}}{b|${iconLenght}}{x|${proportion}}`;
                }
            },
            textStyle: {
                rich: {
                    a: {
                        color: 'rgba(222,223,251,1)',
                        lineHeight: 36,
                        fontWeight: 400,
                        fontSize: 16,
                    },
                    x: {
                        fontSize: 18,
                        fontWeight: 800,
                        color: 'rgba(162, 164, 206, 1)',
                        lineHeight: 36,
                    },
                },
            },
        },
        series: []
    }
    if (type) {
        option.legend.top = 30;
        option.legend.right = 15;
    } else {
        option.legend.left = 130;
        option.legend.bottom = 30;
    }
    data.map((item, index) => {
        let radiusArr = [];
        if (type) {
            radiusArr = ['55%', '20%'];
        } else {
            radiusArr = [(45 - (index * 20) + '%'), (55 - (index * 20) + '%')];
        }
        let dataList = item.map((cur, ind) => {
            return {
                name: cur.name,
                value: cur.value,
                itemStyle: {
                    color: color[ind]
                }
            }
        })
        option.series.push({
            name: "占比",
            type: "pie",
            radius: radiusArr,
            avoidLabelOverlap: false,
            left: 0,
            emphasis: {
                label: {
                    show: false,
                    fontSize: "30",
                    fontWeight: "bold",
                },
            },
            label: {
                show: true,
                position: type ? 'inside' : 'center',
                formatter: (value) => {
                    if (type) {
                        return `{b|${value.value}}`;
                    } else {
                        return `{a|${time}}`;
                    }
                },

                rich: {
                    a: {
                        color: 'rgba(162, 164, 206, 1)',
                        fontWeight: 800,
                        fontSize: 18
                    },
                    b: {
                        fontSize: 14,
                        fontFamily: 'PingFang SC',
                        fontWeight: 400,
                        color: '#FFFFFF',
                        width: 47,
                        height: 24,
                        backgroundColor: '#0C1832',
                        // opacity: 0.9,
                        borderRadius: 12,
                    },
                },
                // align: 'left',
                // verticalAlign: 'middle',
            },
            labelLine: {
                show: false,
            },
            data: dataList,
        })
    })
    chart.setOption(option);
}
