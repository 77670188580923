<template>
    <div id="user-info">
        <div class="peopel-time-search">
            <div class="peopelTimeSearch-left" @click="searchSevenOrFifteen">
                <div class="thisday" :id="activeTime === 1 ? 'active-time' : ''">当日数据</div>
                <div class="thisweek" :id="activeTime === 7 ? 'active-time' : ''">本周数据</div>
                <div class="thismonth" :id="activeTime === 30 ? 'active-time' : ''">本月数据</div>
            </div>
            <div id="draw-line1"></div>
            <div class="peopelTimeSearch-right">
                <div>
                    <el-input placeholder="昵称" prefix-icon="el-icon-search" v-model="userName"></el-input>
                </div>
                <button @click="searchSelect">查询</button>
            </div>
        </div>
        <div class="userinfo-content">
            <ul class="userinfo-list1">
                <li class="table-th">
                    <span>序号</span>
                    <span>昵称</span>
                    <span>时长（小时）</span>
                    <span>距离（千米）</span>
                    <span>消耗（kcal）</span>
                    <span>综合评分</span>
                    <span>体测详情</span>
                </li>
            </ul>
            <ul class="userinfo-list">
                <!--                <li class="table-th">-->
                <!--                    <span>序号</span>-->
                <!--                    <span>昵称</span>-->
                <!--                    <span>时长（小时）</span>-->
                <!--                    <span>距离（千米）</span>-->
                <!--                    <span>消耗（kcal）</span>-->
                <!--                    <span>综合评分</span>-->
                <!--                    <span>体测详情</span>-->
                <!--                </li>-->
                <li v-for="(item, index) in list" class="table-tr">
                    <p>{{ index + 1 }}</p>
                    <p>{{ item.userName }}</p>
                    <p>{{ item.dataTotal }}</p>
                    <p>{{ item.distance }}</p>
                    <p>{{ item.kcal }}</p>
                    <p>{{ item.score }}</p>
                    <p><a href="javascript:;" class="viewDetails" @click="showDetailsModal(item.userId,item.userName)">查看详情</a>
                    </p>
                </li>
            </ul>
            <div class="inoutDetail-pagination">
                <el-pagination
                        layout="prev, pager, next, sizes, jumper, total"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :background="true"
                        :prev-text="'上一页'"
                        :next-text="'下一页'"
                        :page-sizes="[15, 50,  100]"
                        :pager-count="5"
                        :total=totalCount
                ></el-pagination>
            </div>
        </div>
        <div class="detailsData" v-show="detailsModal">
            <div class="peopel-time-search user-data-search">
                <div class="peopelTimeSearch-right userDataSearch-right">{{ nickName }} 的体测数据</div>
                <div class="peopelTimeSearch-left userDataSearch-left" @click="searchSevenOrFifteen1">
                    <div class="thisday" :id="activeTime1 === 1 ? 'active-time' : ''">当日数据</div>
                    <div class="thisweek" :id="activeTime1 === 7 ? 'active-time' : ''">本周数据</div>
                    <div class="thismonth" :id="activeTime1 === 30 ? 'active-time' : ''">本月数据</div>
                </div>
            </div>
            <div class="drawEcharts">
                <div id="draw-line"></div>
            </div>
            <a href="javascript:;" @click="closeDetailsModal" class="closeBtn"></a>
        </div>
        <div v-show="detailsModal" class="detailsMask"></div>
    </div>
</template>
<script>
import {getUserInfo} from "@/api/api";
import {getUserTest} from "@/api/api";
import {line} from "../charts/line.js";
import router from "@/router";

export default {
    data() {
        return {
            activeTime: 1,
            activeTime1: 1,
            userName: "",
            userId: null,
            list: [],
            pageNo: 1,
            pageSize: 15,
            totalCount: 0,
            detailsModal: false,
            userInforData: [],
            radioVal: 0,
            userTestList: [],
            nickName: ""
        };
    },
    created() {
    },
    mounted() {
        document.getElementById("export").style.display = 'show';
        //document.getElementById("export").style.display='none';
        const params = new URLSearchParams();
        params.append("token", localStorage.getItem("singleToken"));
        params.append("rangeDays", 1);
        params.append("pageNo", 1);
        params.append("pageSize", 15);
        this.getUserInfoList(params);
        this.hideExport();
    },
    methods: {
        hideExport() {
            document.getElementById("export").style.display = "block";
        },
        searchSevenOrFifteen(e) {
            this.activeTime = false;
            const params = new URLSearchParams();
            params.append("pageNo", this.pageNo);
            params.append("pageSize", this.pageSize);
            params.append("userName", this.userName);
            params.append("token", localStorage.getItem("singleToken"));
            if (e.target.className === "thisday") {
                this.activeTime = true;
                this.activeTime = 1;
                params.append("rangeDays", 1);
            } else if (e.target.className === "thisweek") {
                this.activeTime = true;
                this.activeTime = 7;
                params.append("rangeDays", 7);
            } else if (e.target.className === "thismonth") {
                this.activeTime = true;
                this.activeTime = 30;
                params.append("rangeDays", 30);
            }
            this.getUserInfoList(params);
        },
        searchSevenOrFifteen1(e) {
            this.activeTime1 = false;
            const params = new URLSearchParams();
            params.append("userId", this.userId);
            params.append("token", localStorage.getItem("singleToken"));
            if (e.target.className === "thisday") {
                this.activeTime1 = true;
                this.activeTime1 = 1;
                params.append("rangeDays", 1);
            } else if (e.target.className === "thisweek") {
                this.activeTime1 = true;
                this.activeTime1 = 7;
                params.append("rangeDays", 7);
            } else if (e.target.className === "thismonth") {
                this.activeTime1 = true;
                this.activeTime1 = 30;
                params.append("rangeDays", 30);
            }
            this.getUserTestList(params);
        },
        handleSizeChange(val) {
            this.pageSize = val;
            const params = new URLSearchParams();
            params.append("token", localStorage.getItem("token"));
            params.append("pageNo", this.pageNo);
            params.append("userName", this.userName);
            params.append("rangeDays", this.activeTime);
            params.append("pageSize", this.pageSize);
            this.getUserInfoList(params);
        },
        handleCurrentChange(val) {
            this.pageNo = val;
            const params = new URLSearchParams();
            params.append("token", localStorage.getItem("token"));
            params.append("pageNo", val);
            params.append("userName", this.userName);
            params.append("pageSize", this.pageSize);
            params.append("rangeDays", this.activeTime);
            this.getUserInfoList(params);
        },
        searchSelect() {
            const params = new URLSearchParams();
            params.append("token", localStorage.getItem("token"));
            params.append("pageNo", this.pageNo);
            params.append("userName", this.userName);
            params.append("pageSize", this.pageSize);
            params.append("rangeDays", this.activeTime);
            this.getUserInfoList(params);
        },
        getUserInfoList(params) {
            getUserInfo(params).then((res) => {
                if (res.code === "API200") {
                    this.UserInfoDeat(res.data.list);
                    this.totalCount = res.data.total;
                } else if (res.code === "API300") {
                    router.push("/");
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        UserInfoDeat(list) {
            this.list = list;
        },
        //打开查看详情弹窗
        showDetailsModal(userId, nickName) {
            this.userId = userId;
            this.nickName = nickName;
            this.detailsModal = true;
            this.activeTime1 = 1;
            const params = new URLSearchParams();
            params.append("token", localStorage.getItem("token"));
            params.append("userId", userId);
            params.append("rangeDays", 1);
            this.getUserTestList(params);
        },
        //关闭查看详情弹窗
        closeDetailsModal() {
            this.detailsModal = false;
        },
        clickRadio(val) {
            if (this.radioVal == val) {
                this.radioVal = 0;
            } else {
                this.radioVal = val;
            }
        },
        getUserTestList(params) {
            getUserTest(params).then((res) => {
                if (res.code === "API200") {
                    line(
                        document.getElementById("draw-line"),
                        res.data.userDetails,
                        null
                    );
                } else if (res.code === "API300") {
                    router.push("/");
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
    },
};
</script>
<style lang="less">
#draw-line {
    z-index: 1100;
}

#user-info {
    width: 100%;
    height: 6rem;
    padding: 30px 0;
    position: relative;

    .peopelTimeSearch-right {
        .el-input__inner {
            width: 1.82rem;
            height: 0.4rem;
            background: #101c37;
            border: 1px solid #6076ad;
            border-radius: 2px;

            .el-icon-search {
                width: 1.82rem;
                height: 0.4rem;
                line-height: 0.4rem;
            }

            .el-input__icon {
                width: 20px;
                height: 20px;
                line-height: 0.4rem;
            }
        }

        button {
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 800;
            color: rgba(255, 255, 255, 1);
            line-height: 36px;
            background: url(../../assets/homeImg/daochu_back.png) no-repeat;
            background-size: 100% 100%;
        }
    }

    .thismonth {
        margin-left: 10px;
    }

    .userinfo-content {
        width: 100%;
        height: 6rem;
        margin-top: 20px;
    }

    .userinfo-list1 {
        width: 100%;
        overflow: auto;

        li {
            width: 100%;
            height: 46px;
            line-height: 46px;
            color: rgba(221, 223, 251, 1);
            font-size: 16px;
            text-align: center;
            display: flex;
            justify-content: space-around;

            :nth-child(1) {
                width: 10%;
            }

            :nth-child(2) {
                width: 15%;
            }

            :nth-child(3) {
                width: 15%;
            }

            :nth-child(4) {
                width: 15%;
            }

            :nth-child(5) {
                width: 20%;
            }

            :nth-child(6) {
                width: 10%;
            }

            :nth-child(7) {
                width: 15%;
            }

            .viewDetails {
                font-size: 16px;
                font-weight: 400;
                text-decoration: underline;
                color: #00D8FF;
                line-height: 35px;
            }
        }

        li:nth-child(even) {
            background: rgba(96, 118, 173, 0.06);
            border-radius: 2px;
        }
    }

    .userinfo-list {
        width: 100%;
        height: 70%;
        overflow: auto;

        li {
            width: 100%;
            height: 46px;
            line-height: 46px;
            color: rgba(221, 223, 251, 1);
            font-size: 16px;
            text-align: center;
            display: flex;
            justify-content: space-around;

            :nth-child(1) {
                width: 10%;
            }

            :nth-child(2) {
                width: 15%;
            }

            :nth-child(3) {
                width: 15%;
            }

            :nth-child(4) {
                width: 15%;
            }

            :nth-child(5) {
                width: 20%;
            }

            :nth-child(6) {
                width: 10%;
            }

            :nth-child(7) {
                width: 15%;
            }

            .viewDetails {
                font-size: 16px;
                font-weight: 400;
                text-decoration: underline;
                color: #00D8FF;
                line-height: 35px;
            }
        }

        li:nth-child(even) {
            background: rgba(96, 118, 173, 0.06);
            border-radius: 2px;
        }
    }

    .userinfo-list::-webkit-scrollbar {
        width: 8px;
        background: #3f5381;
    }

    .userinfo-list::-webkit-scrollbar-thumb {
        background-color: #090d26;
        border-radius: 10px;
        -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
    }

    .userinfo-list1 .table-th {
        font-size: 18px;
        font-weight: 800;
        color: #00D8FF;
        font-family: PingFang SC;
    }

    #active-time {
        background: rgba(11, 22, 48, 1);
        color: rgba(0, 216, 255, 1);
        border: 1px solid rgba(96, 118, 173, 1);
        border-radius: 2px;
    }


    .detailsData {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        background: url(../../assets/userInforImg/modalBg.png) no-repeat;
        background-size: 100% 100%;
        z-index: 1000;

        .closeBtn {
            display: block;
            position: absolute;
            bottom: -40px;
            width: 40px;
            height: 40px;
            left: 50%;
            margin-left: -20px;
            background: url(../../assets/userInforImg/guanbi-btn.png) no-repeat;
        }

        .drawEcharts {
            width: 8rem;
            height: 4rem;
            margin-top: 20px;
            box-sizing: border-box;
            padding-left: 52px;
            padding-right: 59px;

            #draw-line {
                margin: auto;
                width: 9rem;
                height: 4.2rem;
                background-color: rgba(96, 118, 173, 0.06);
                padding-top: 2%;
            }

        }

        .peopel-time-search {
            margin-top: 20 rpx;
            width: 100%;
            height: 0.4rem;
            display: flex;
            justify-content: space-between;

            .peopelTimeSearch-left {
                display: flex;

                div {
                    width: 1.5rem;
                    height: 0.4rem;
                    line-height: 0.4rem;
                    text-align: center;
                    background: rgba(16, 28, 55, 1);
                    color: rgba(162, 164, 206, 1);
                    border: 1px solid rgba(96, 118, 173, 1);
                    border-radius: 2px;
                }

                :nth-child(2),
                :nth-child(3),
                :nth-child(4) {
                    margin-left: 10px;
                }

                #active-time {
                    background: rgba(11, 22, 48, 1);
                    color: rgba(0, 216, 255, 1);
                    border: 1px solid rgba(96, 118, 173, 1);
                    border-radius: 2px;
                }
            }

            .userDataSearch-right {
                margin-top: 14px;
                width: 180px;
                height: 16px;
                font-size: 16px;
                font-weight: 800;
                color: #A1A3CD;
                line-height: 35px;
                text-align: left;

            }

            .peopelTimeSearch-right {
                display: flex;
                justify-content: space-around;
                align-items: center;

                .peopelTimeSearch-time {
                    width: 3.3rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    span {
                        width: 0.3rem;
                        color: rgba(162, 164, 206, 1);
                    }

                    .el-input__inner {
                        width: 1.5rem;
                        height: 0.4rem;
                        line-height: 0.4rem;
                        text-align: center;
                        background: rgba(16, 28, 55, 1);
                        color: rgba(162, 164, 206, 1);
                        border: 1px solid rgba(96, 118, 173, 1);
                        border-radius: 2px;

                        input {
                            font-size: 0.16rem;
                            color: rgba(162, 164, 206, 1);
                        }
                    }
                }
            }
        }

        .user-data-search {
            box-sizing: border-box;
            padding-top: 66px;
            padding-left: 52px;
            padding-right: 59px;
            height: auto;
        }

    }

    .detailsMask {
        position: fixed;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
        top: 0;
        left: 0;
        background: rgba(17, 17, 17, 0.7);
    }

}
</style>

